import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

class WithFeatureEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {trackVisibilityChanges: true};
    this.onClick = this.onClick.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  onVisibilityChange(isVisible) {
    if (isVisible && this.state.trackVisibilityChanges) {
      this._track('Viewed Homepage Feature');
      // Once the feature has been recorded as viewed, don't track the event again.
      this.setState({trackVisibilityChanges: false});
    }
  }

  onClick() {
    this._track('Clicked Homepage Feature');
  }

  _track(event) {
    window.metrics.track(event, {
      feature: {
        id: this.props.module.id,
        name: this.props.module.name,
        type: this.props.module.type,
      },
      user: {
        orderCount: this.props.orderCount,
      },
    });
  }

  render() {
    return (
      <div className="with-feature-events" onClick={this.onClick}>
        <VisibilitySensor
          active={this.state.trackVisibilityChanges}
          onChange={this.onVisibilityChange}
          // Track visibility when at least `visibilityInsetTop` px of the element is visible.
          minTopValue={this.props.visibilityInsetTop}
          partialVisibility
        >
          {this.props.children}
        </VisibilitySensor>
      </div>
    );
  }
}

WithFeatureEvents.propTypes = {
  children: PropTypes.node,
  module: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  orderCount: PropTypes.number.isRequired,
  visibilityInsetTop: PropTypes.number,
};

WithFeatureEvents.defaultProps = {
  visibilityInsetTop: 150, // arbitrary starting point - show's the feature module title and a portion of the content.
};

export default WithFeatureEvents;
