import React, {Component} from 'react';
import PropTypes from 'prop-types';

import FeaturedCategoriesModule from '../featured_categories_module';
import FeaturedContentModule from '../featured_content_module';
import ProductCollectionModule from '../product_collection_module';
import FeaturedProducersModule from '../featured_producers_module';
import WithFeatureEvents from '../with_feature_events';

class HomePageModule extends Component {
  getModuleComponent(module) {
    switch (module.type) {
      case 'curated-collection':
        return ProductCollectionModule;
      case 'featured-content':
        return FeaturedContentModule;
      case 'tag-collection':
        return ProductCollectionModule;
      case 'subcategory-collection':
        return ProductCollectionModule;
      case 'featured-producers':
        return FeaturedProducersModule;
      case 'featured-categories':
        return FeaturedCategoriesModule;
      default:
        return () => (
          <div>
            <h2>{module.name}</h2>
            <p>
              Module {'"'}
              {module.type}
              {'"'} Not implemented yet
            </p>
          </div>
        );
    }
  }

  render() {
    const Module = this.getModuleComponent(this.props.module);
    return (
      <div
        data-score={this.props.module.score}
        data-id={this.props.module.id}
        className={`personalized-home-page__module personalized-home-page__module-${this.props.module.type}`}
      >
        <WithFeatureEvents module={this.props.module} orderCount={this.props.user?.orderCount ?? 0}>
          <div className="personalized-home-page__module-container">
            <Module
              module={this.props.module}
              isLoggedIn={Boolean(this.props.user)}
              segmentFeature={`${this.props.module.type}-module`}
              features={this.props.features}
            />
          </div>
        </WithFeatureEvents>
      </div>
    );
  }
}

HomePageModule.propTypes = {
  user: PropTypes.object,
  module: PropTypes.object,
  features: PropTypes.array,
};

export default HomePageModule;
