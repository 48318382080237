import $ from 'jquery';

import {getJson, postJson} from 'web/helpers/api_helpers';

export function getMerchandisingFeatures({interests, showAllFeatures, isLoggedIn} = {}) {
  const queryObj = {isLoggedIn};
  if (interests.length) {
    queryObj.interests = interests.join(',');
  }

  if (showAllFeatures) {
    queryObj.showAllFeatures = 'true';
  }

  return getJson(`/api/merchandising/features?${$.param(queryObj)}`);
}

export function updateFoodInterests({userId, sessionId, interests}) {
  return postJson('/api/merchandising/update-food-interests', {
    body: {userId, sessionId, interests},
  });
}
