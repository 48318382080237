import React from 'react';
import {string, object, shape} from 'prop-types';

import segmentAnalytics from '@analytics/client';

const RenderCategory = ({category, type, name}) => {
  const handleClick = () => {
    segmentAnalytics.track('ctaClicked', {
      destination: category.url,
      feature: 'home',
      moduleName: name,
      moduleType: type,
      pageUrl: window.location.href,
      text: category.name,
      type: 'tile',
    });
  };

  return (
    <a
      href={category.url}
      onClick={handleClick}
      className="featured-categories-module__category-tile"
    >
      <img
        className="featured-categories-module__category-tile__image"
        src={`${category.imageUrl}?w=380&h=238&fm=jpg&auto=compress&fit=crop`}
      />
      <div className="featured-categories-module__category-tile__title">{category.name}</div>
    </a>
  );
};

RenderCategory.propTypes = {
  category: shape({
    name: string.isRequired,
    url: string.isRequired,
    imageUrl: string.isRequired,
  }).isRequired,
  type: string.isRequired,
  name: string.isRequired,
};

const FeaturedCategoriesModule = ({module}) => (
  <div className="featured-categories-module">
    <h2>{module.name}</h2>
    <div className="featured-categories-module__categories">
      {module.categories.map((category) => (
        <RenderCategory
          key={category.name}
          category={category}
          type={module.type}
          name={module.name}
        />
      ))}
    </div>
  </div>
);

FeaturedCategoriesModule.propTypes = {
  module: object.isRequired,
};

export default FeaturedCategoriesModule;
