import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import cookies from 'web/helpers/cookies';
import Modal from 'web/components/modal';

import {actions} from '../../duck';

class InterestsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interests: props.selectedFoodInterests?.reduce(
        (acc, interest) => ({...acc, [interest]: true}),
        {},
      ),
    };
  }

  handleToggleInterest = (event) => {
    const {interest} = event.target.dataset;
    this.setState((prevState) => ({
      interests: {...prevState.interests, [interest]: !prevState.interests[interest]},
    }));
  };

  handleContinue = () => {
    const userId = this.props.user ? this.props.user.id : null;
    const sessionId = this.props.session.id;
    const interests = Object.keys(this.state.interests).filter((key) => this.state.interests[key]);
    this.props.dispatch(
      actions.setFoodInterests({
        interests,
        sessionId,
        userId,
        showAllFeatures: this.props.showAllFeatures,
        featureFlags: this.props.features,
      }),
    );
    this.props.onSubmit();
  };

  handleCancel = () => {
    this.props.dispatch(actions.cancelFoodInterests());
    this.props.onSubmit();
  };

  handleDismiss = () => {
    const interests = Object.keys(this.state.interests).filter((key) => this.state.interests[key]);
    cookies.setDismissedInterests();
    this.props.dispatch(actions.dismissFoodInterests({interests}));
    this.props.onSubmit();
  };

  render() {
    return (
      <Modal className="personalized-home-page-interests" onClose={this.handleDismiss}>
        <h3 className="personalized-home-page-interests__heading">
          What foods are you interested in?
        </h3>
        <ul className="personalized-home-page-interests__list">
          {this.props.availableFoodInterests.map(({label, key}) => (
            <li
              key={`interest-${key}`}
              onClick={this.handleToggleInterest}
              data-interest={key}
              className={classNames('personalized-home-page-interests__list-item', {
                selected: this.state.interests[key],
              })}
            >
              {label}
            </li>
          ))}
        </ul>
        {this.props.userHasFoodInterests ? (
          <div className="personalized-home-page-interests__footer">
            <div onClick={this.handleContinue} className="personalized-home-page-interests__save">
              Save
            </div>
            <div onClick={this.handleCancel} className="personalized-home-page-interests__cancel">
              Cancel
            </div>
          </div>
        ) : (
          <div className="personalized-home-page-interests__footer">
            <div
              onClick={this.handleContinue}
              className="personalized-home-page-interests__continue"
            >
              Continue
            </div>
            <div onClick={this.handleDismiss} className="personalized-home-page-interests__dismiss">
              I{"'"}ll do this later
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

const ConnectedInterestsModal = connect((state) => state)(InterestsModal);

export default ConnectedInterestsModal;
