import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect as ReactRedirect} from 'react-router';
import {createPath} from 'history';

const IS_NODE = typeof window !== 'object';

/*
 * Just like a react-router redirect but will throw an interrupt and trigger
 * server-side redirection via the routing middlware
 *
 * Also supports redirecting outside of single page app with `external` prop.
 */
export default class Redirect extends Component {
  render() {
    const path = typeof this.props.to === 'string' ? this.props.to : createPath(this.props.to);
    if (IS_NODE) {
      // trigger a 302 when server-side rendering
      const redirectInterrupt = new Error('Please redirect');
      redirectInterrupt.path = path;
      redirectInterrupt.type = 'REDIRECT_INTERRUPT';
      throw redirectInterrupt;
    }
    if (this.props.external && !IS_NODE) {
      window.location = path;
      return null;
    }
    return <ReactRedirect {...this.props} />;
  }
}

Redirect.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
};
