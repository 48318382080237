import React from 'react';

import {FeaturedContentData} from 'domain/catalog/merchandising/models/merchandising_feature/types';

const FeaturedContentModule: React.FC<{
  module: FeaturedContentData;
}> = ({module}) => {
  return (
    <div className="home-page__featured-content-module-container">
      <img
        className="home-page__featured-content-module-image"
        alt={module.imageAlt}
        src={`${module.imageUrl}?w=1600&h=860&q=60&fit=crop`}
        onClick={() => {
          window.location.href = module.cta.url;
        }}
      />
      <div className="home-page__featured-content-module-description">
        <h2 className="home-page__featured-content-module-description-header">{module.title}</h2>
        <p className="home-page__featured-content-module-description-text">{module.description}</p>
        <a href={module.cta.url}>
          <button
            type="button"
            className="home-page__featured-content-module-description-cta pill-link"
          >
            {module.cta.text}
          </button>
        </a>
      </div>
    </div>
  );
};

export default FeaturedContentModule;
