import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Switch} from 'react-router';

import {Route, Router} from 'web/helpers/routing';
import MarketLayout from 'web/components/market_layout';
import {reducer as authFlowReducer} from 'web/components/auth_flow/duck';
import {reducer as signUpModalReducer} from 'web/helpers/sign_up_modal_duck';

import HomeScreen from './components/home_screen';
import {reducer as homePageReducer} from './duck';

class HomeApp extends Component {
  static reducer(state, action) {
    let newState = MarketLayout.reducer(state, action);
    newState = homePageReducer(newState, action);
    newState = authFlowReducer(newState, action);
    newState = signUpModalReducer(newState, action);
    return newState;
  }

  constructor(props) {
    super(props);
    const routePrefix = `/home`;
    this.router = (
      <Router location={this.props.location}>
        <Switch>
          <Route exact path={routePrefix} component={HomeScreen} screenName="Home" />
          <Route
            path={`${routePrefix}/step/interests`}
            showFoodInterests
            component={HomeScreen}
            screenName="Home"
          />
        </Switch>
      </Router>
    );
  }

  render() {
    return this.router;
  }
}

HomeApp.propTypes = {
  location: PropTypes.string,
  defaultZipCode: PropTypes.string,
  zipCode: PropTypes.string,
};

HomeApp.pageName = false;

export default connect((state) => state)(HomeApp);
