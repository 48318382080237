import {find, omit, result} from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route as ReactRoute} from 'react-router';

import PageEvents from '@analytics/client/page_events';

import Redirect from './redirect';

export default class Route extends Component {
  constructor(props) {
    super(props);
    if (!props.screenName) {
      throw new Error('screenName is a required property for Page metrics tracking');
    }
    this.onRouteChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      this.onRouteChange();
    }
  }

  onRouteChange() {
    if (typeof window !== 'undefined') {
      if (this.props.scrollToTop) {
        window.scrollTo(0, 0);
      }
      PageEvents(this.props.screenName, this.props, location, document);
    }
  }

  resolveRedirect() {
    let redirects = [];

    if (this.props.redirect != null) {
      redirects = !Array.isArray(this.props.redirect) ? [this.props.redirect] : this.props.redirect;
    }

    // pick the first valid redirect option
    return find(
      redirects,
      (redirect) => result(redirect, 'if', false) || !result(redirect, 'unless', true),
    );
  }

  render() {
    const routeProps = omit(this.props, 'component', 'screenName', 'scrollToTop', 'redirect');
    const selectedRedirect = this.resolveRedirect();
    const {component: RouteComponent} = this.props;

    return (
      <ReactRoute
        {...routeProps}
        render={(renderProps) => {
          if (selectedRedirect) {
            return (
              <Redirect
                to={`${selectedRedirect.to}${
                  (this.props.location && this.props.location.search) || ''
                }`}
                external={selectedRedirect.external}
              />
            );
          }
          const innerProps = {
            ...omit(
              this.props,
              'exact',
              'component',
              'path',
              'screenName',
              'scrollToTop',
              'redirect',
            ),
            ...renderProps,
          };
          return <RouteComponent route={innerProps} />;
        }}
      />
    );
  }
}

Route.propTypes = {
  ...ReactRoute.propTypes,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  screenName: PropTypes.string,
  scrollToTop: PropTypes.bool,
};
