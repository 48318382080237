import classNames from 'classnames';
import React, {FC} from 'react';

export interface FeaturedProducersModuleProps {
  module: {name: string};
}

const FeaturedProducersModule: FC<FeaturedProducersModuleProps> = ({module}) => {
  const featuredProducersSlugs = [
    // 1st line
    'fullbellymarketplace',
    'froghollow',
    'stepladdercreamery',
    'cloversfbay',
    'foglinefarmsfbay',

    // 2nd line
    'momofuku',
    'theslanteddoor',
    'pizzeriadelfina',
    'sonomacountymeatco',
    'roliroti',
  ];

  return (
    <div className="personalized-home-page__featured-producers-module">
      <h2>{module.name}</h2>
      <div className="personalized-home-page__featured-producers-module__grid">
        {featuredProducersSlugs.map((slug) => (
          <a
            key={slug}
            className={classNames(
              'personalized-home-page__featured-producers-module__brand-tile',
              'brand-image',
              `brand-${slug}`,
            )}
            href={`/${slug}`}
          >
            <div className="personalized-home-page__featured-producers-module__brand-tile-spacer" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProducersModule;
