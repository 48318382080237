import {updateFoodInterests, getMerchandisingFeatures} from './api_client';

export const SET_FOOD_INTERESTS = 'HOME_PAGE_SET_FOOD_INTERESTS';
export const CANCEL_FOOD_INTERESTS = 'HOME_PAGE_CANCEL_FOOD_INTERESTS';
export const DISMISS_FOOD_INTERESTS = 'HOME_PAGE_DISMISS_FOOD_INTERESTS';
export const LOADING_FEATURES = 'HOME_PAGE_LOADING_FEATURES';
export const LOADING_FEATURES_SUCCESS = 'HOME_PAGE_LOADING_FEATURES_SUCCESS';
export const LOADING_FEATURES_FAILURE = 'HOME_PAGE_LOADING_FEATURES_FAILURE';

export const actions = {
  setFoodInterests:
    ({userId, sessionId, interests, showAllFeatures, featureFlags}) =>
    (dispatch) => {
      dispatch({type: SET_FOOD_INTERESTS, interests});
      return updateFoodInterests({userId, sessionId, interests}).then(() =>
        actions.fetchMerchandisingFeatures({interests, showAllFeatures, featureFlags})(dispatch),
      );
    },

  cancelFoodInterests: () => ({type: CANCEL_FOOD_INTERESTS}),

  dismissFoodInterests: ({interests}) => ({type: DISMISS_FOOD_INTERESTS, interests}),

  fetchMerchandisingFeatures:
    ({interests, showAllFeatures, featureFlags, isLoggedIn} = {}) =>
    (dispatch) => {
      dispatch({type: LOADING_FEATURES});
      return getMerchandisingFeatures({interests, showAllFeatures, featureFlags, isLoggedIn})
        .then((merchandisingFeatures) => {
          dispatch({type: LOADING_FEATURES_SUCCESS, merchandisingFeatures});
        })
        .catch((error) => {
          dispatch({type: LOADING_FEATURES_FAILURE, error});
        });
    },
};

export function reducer(state, action) {
  let newState = state;
  switch (action.type) {
    case SET_FOOD_INTERESTS: {
      newState = {
        ...newState,
        selectedFoodInterests: action.interests,
        userHasFoodInterests: action.interests.length > 0,
        showFoodInterests: false,
      };
      break;
    }
    case DISMISS_FOOD_INTERESTS: {
      newState = {
        ...newState,
        showFoodInterests: false,
        selectedFoodInterests: action.interests,
      };
      break;
    }
    case CANCEL_FOOD_INTERESTS: {
      newState = {
        ...newState,
        showFoodInterests: false,
      };
      break;
    }
    case LOADING_FEATURES: {
      newState = {...newState, loadingMerchandisingFeatures: true};
      break;
    }
    case LOADING_FEATURES_SUCCESS: {
      const {merchandisingFeatures} = action;
      const featureProducts = merchandisingFeatures
        .flatMap(({products}) => products)
        .filter(Boolean);
      const products = {...state.products};
      for (const featureProduct of featureProducts) {
        products[featureProduct.id] = featureProduct;
      }
      newState = {
        ...newState,
        loadingMerchandisingFeatures: false,
        products,
        merchandisingFeatures,
      };
      break;
    }
    case LOADING_FEATURES_FAILURE: {
      // Consider showing error to user
      newState = {
        ...newState,
        loadingMerchandisingFeatures: false,
        merchandisingFeatures: [],
        homepageError: action.error,
      };
      break;
    }
    default: {
      // no op
    }
  }
  return newState;
}
