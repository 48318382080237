import React, {Component} from 'react';
import {isString} from 'lodash';
import {connect} from 'react-redux';

import MarketTile from 'web/components/market_tile';

class ProductCollectionModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.segmentFeature = props.segmentFeature;
  }

  handleSeeMore = () => {
    this.setState({expanded: true});
  };

  renderProduct = (product, index) => {
    let quantityInBasket = 0;
    if (this.props.basketItemsByProductId[product.id]) {
      quantityInBasket = this.props.basketItemsByProductId[product.id].quantity || 0;
    }

    return (
      <MarketTile
        key={index}
        product={product}
        position={index}
        quantityInBasket={quantityInBasket}
        isFavorited={this.props.favorites.indexOf(product.id) !== -1}
        segmentFeature={this.segmentFeature}
        currentFulfillmentDay={this.props.currentFulfillmentDay}
        fulfillmentDaySummaries={this.props.fulfillmentDaySummaries}
      />
    );
  };

  renderSeeAllFooter = () => {
    return (
      isString(this.props.module.seeAllText) && (
        <div>
          <a
            href={this.props.module.seeAllUrlDesktop}
            className="product-collection-module__footer__see-all-desktop"
          >
            <div className="product-collection-module__footer__see-all__text">
              {this.props.module.seeAllText} {'>'}
            </div>
          </a>
        </div>
      )
    );
  };

  render() {
    const length = this.state.expanded
      ? this.props.module.fullLength
      : this.props.module.previewLength;
    if (this.props.module.products.length < 2) {
      return null;
    }

    const products = this.props.module.products.slice(0, length);
    const showFooter =
      this.props.module.products.length > this.props.module.previewLength && !this.state.expanded;
    return (
      <div className="product-collection-module">
        <h2>{this.props.module.name}</h2>
        <div className="product-collection-module__products">
          {products.map(this.renderProduct)}
        </div>
        <div className="product-collection-module__footer">
          {showFooter ? (
            <div
              className="product-collection-module__footer__see-more"
              onClick={this.handleSeeMore}
            >
              <span className="product-collection-module__footer__see-more__icon">
                <i className="icon icon-plus-thick" />
              </span>
              <span className="product-collection-module__footer__see-more__text">See more</span>
            </div>
          ) : (
            this.renderSeeAllFooter()
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => state)(ProductCollectionModule);
