import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import segmentAnalytics from '@analytics/client';
import assetPath from 'web/helpers/asset_path';

export interface AnnouncementBannerData {
  ctaLink: string;
  ctaText: string;
  text: string;
}

const AnnouncementBanner: FC<{feature: string}> = ({feature}) => {
  const activeAnnouncement = useSelector<
    {activeAnnouncement: AnnouncementBannerData | null},
    AnnouncementBannerData | null
  >((state) => state.activeAnnouncement);

  if (activeAnnouncement == null) return null;
  const {ctaLink, ctaText, text} = activeAnnouncement;

  const handleClick = (): void => {
    segmentAnalytics.track('ctaClicked', {
      type: 'banner',
      text: ctaText,
      pageUrl: window.location.pathname,
      destination: ctaLink,
      feature,
    });
  };

  return (
    <div className="announcement-banner__container">
      <div className="announcement-banner__content">
        <img
          className="announcement-banner__icon"
          alt="Announcement horn"
          src={`${assetPath('/img/svg/horn.svg')}?auto=format`}
        />
        <a
          className="announcement-banner__text"
          href={ctaLink}
          onClick={handleClick}
          target="_blank"
        >
          {text}
        </a>
        <span className="announcement-banner__spacer" />
        <a
          className="announcement-banner__cta"
          href={ctaLink}
          onClick={handleClick}
          target="_blank"
        >
          {ctaText}
        </a>
      </div>
    </div>
  );
};

export default AnnouncementBanner;
