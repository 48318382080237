import React, {ReactNode} from 'react';
import Slider from 'react-slick';

import Image from 'web/components/image';

const FeaturedContentCarousel: React.FC<{children: ReactNode}> = ({children}) => {
  return (
    <div className="home-page__featured-content-carousel" data-testid="featured-content-carousel">
      <Slider
        dots
        infinite
        autoplaySpeed={5000}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
        // Hide the arrows once we lose room for them to float around carousel
        responsive={[{breakpoint: 1300, settings: {arrows: false}}]}
        prevArrow={
          // React will throw some false warnings here because slick injects `currentSlide` and `slideCount` props
          <Image
            className="home-page__featured-content-carousel-arrow"
            src="/img/svg/icons/caret-left.svg"
            alt="Featured content previous"
          />
        }
        nextArrow={
          // React will throw some false warnings here because slick injects `currentSlide` and `slideCount` props
          <Image
            className="home-page__featured-content-carousel-arrow"
            src="/img/svg/icons/caret-right.svg"
            alt="Featured content next"
          />
        }
      >
        {children}
      </Slider>
    </div>
  );
};

export default FeaturedContentCarousel;
