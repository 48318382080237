import {createBrowserHistory, createMemoryHistory} from 'history';
import React, {Component} from 'react';
import {Router as ReactRouter} from 'react-router';

const IS_NODE = typeof window !== 'object';

export default class Router extends Component {
  constructor(props) {
    super(props);
    if (typeof this.history === 'undefined') {
      if (IS_NODE) {
        this.history = createMemoryHistory({initialEntries: [props.location]});
      } else {
        this.history = createBrowserHistory();
      }
    }
  }

  render() {
    return <ReactRouter history={this.history}>{this.props.children}</ReactRouter>;
  }
}
